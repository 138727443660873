<template>
<div>
  <div class="page-container" v-if="!show_report">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <router-link to="/clientes" class="page-route-select-text">
        Clientes
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Analítico
      </div>
    </div>
    <div class="page-title">
      Analítico
    </div>
    <div class="orders-page__top-actions-container">
      <router-link to="/clientes">
        <Button :iconName="'arrow_back'" title="Voltar" :text="'Voltar'" :action="() => {}" />
      </router-link>
    </div>
    <div class="orders-page__actions-container">
      <StandardInput :placeholder="'Selecione um estado'" 
                     :title="'Estado'"
                     :type="'select'"
                     :list="statesList"
                     :action="setStateValue" 
                     class="orders-page__content-inputs"/>
      <StandardInputListFilteredAuto class="orders-page__content-inputs"
                                     title="Cidade" 
                                     :propPlaceholder="selectCity" 
                                     :action="setCityValue"
                                     :list="citiesList"
                                     :actionTwo="() => {}"
                                     :type="'select'"/>       
      <StandardInput :placeholder="'Selecione uma revenda'" 
                     :title="'Revenda'"
                     :type="'select'"
                     :list="resalesList"
                     :action="setResaleValue" 
                     class="orders-page__content-inputs"/>  
      <Button style="position: relative; top: 10px;" 
              :iconName="'delete'" 
              title="Limpar Filtros" 
              :text="'Limpar Filtros'" 
              :action="clearFilters" />
    </div>
    <div class="page-selected-filters-container">
	    <div v-if="filters.state.est_sigla" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.state.est_sigla }}</div>
        <span @click="setStateValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.city.cid_nome" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.city.cid_nome }}</div>
        <span @click="setCityValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.resale.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.resale.name }}</div>
        <span @click="setResaleValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="is_loading" />
    <no-data-available v-if="!is_loading && clients.length == 0" :text="'Nenhum produto encontrado'" />
    <div v-if="!is_loading && clients.length > 0" class="page-table-container">
      <div>
        <div class="page-table-header">
          <div class="page-table-header-text select-width"></div>
          <div class="page-table-header-text id-width">ID</div>
          <div class="page-table-header-text client-width">Cliente</div>
          <div class="page-table-header-text cnpj-width">CNPJ</div>
          <div class="page-table-header-text trade-name-width">Nome Fantasia</div>
          <div class="page-table-header-text state-width">Estado</div>
          <div class="page-table-header-text city-width">Cidade</div>
          <div class="page-table-header-text segment-width">Segmento</div>
        </div>
        <div v-for="(client, idx) in clients">
          <div :key="'head-office-' + client.head_office.id"
               v-if="client.head_office" 
               @click="client.selected = !client.selected"
               :class="[ 'client', getSpecialBackground(idx), { selected: client.selected } ]">
            <div class="page-table-row">
              <div class="page-table-desc-column select-width">
                <div class="page-table-header-mobile"></div>
                <div class="checkbox-wrapper">
                  <cc-checkbox :checked="client.selected" @change="(evt) => {client.selected = evt.target.checked}"/>
                </div>
              </div>
              <div class="page-table-desc-column id-width">
                <div class="page-table-header-mobile">ID</div>
                {{ client.id }}
              </div>
              <div class="page-table-desc-column client-width">
                <div class="page-table-header-mobile">Cliente</div>
                <span class="material-icons-outlined seller-icon" title="client.contact ? client.contact.toUpperCase() : ''" :style="{ visibility: !client.head_office.is_head_office ? 'hidden' : 'visible' }">
                  person
                </span>
                {{ client.head_office.social_reazon }}
              </div>
              <div class="page-table-desc-column cnpj-width">
                <div class="page-table-header-mobile">CNPJ</div>
                {{ client.head_office.cnpj || "" | VMask('## ### ###/####-##') }}
              </div>
              <div class="page-table-desc-column trade-name-width">
                <div class="page-table-header-mobile">Nome Fantasia</div>
                {{ client.head_office.trading_name }}
              </div>
              <div class="page-table-desc-column state-width">
                <div class="page-table-header-mobile">Estado</div>
                {{ client.head_office.state && client.head_office.state.name }}
              </div>
              <div class="page-table-desc-column city-width">
                <div class="page-table-header-mobile">Cidade</div>
                {{ client.head_office.city && client.head_office.city.name }}
              </div>
              <div class="page-table-desc-column segment-width">
                <div class="page-table-header-mobile">Segmento</div>
                {{ client.sectors && client.sectors.map(s => s.name).filter(s => s).join(', ') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cc-paginacao :key="'pag'"
                  classes="orange"
                  :items_by_page_value="items_by_page_value"
                  :total="total_items"
                  :page="paginaAtual"
                  :page_limit="11"
                  v-on:change="list($event)"/>
  </div>
  <ClientReport v-if="show_report && selected_clients.length > 1"
                :clients="selected_clients"
                @close="show_report = false" />
  <button class="analyze" title="Analisar" @click="open_report" v-if="!show_report">
    <i class="fas fa-chart-line"></i>
  </button>
  <button class="analyze return" title="Voltar" @click="close_report" v-else>
    <i class="fas fa-chevron-left"></i>
  </button>
</div>
    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid" v-if="!show_report">
                <div class="row">
                    <div class="col-12">
                        <form action="" @submit.prevent="list()">
                            <div class="row py-3">
                                <div class="col col-md-2 col-lg">
                                    <label for="" class="label-control">Selecione um estado</label>
                                    <cc-select-v2
                                        :tracker="'est_id'"
                                        :text="'est_nome'"
                                        :searchable="true"
                                        :options="states"
                                        v-model="filters.state" />
                                </div>
                                <div class="col col-md-2 col-lg">
                                    <label for="" class="label-control">Selecione uma cidade</label>
                                    <cc-select-v2
                                        :tracker="'cid_id'"
                                        :text="'cid_nome'"
                                        :searchable="true"
                                        :options="available_cities"
                                        v-model="filters.city" />
                                </div>
                                <div class="col col-md-2 col-lg">
                                    <label for="" class="label-control">Selecione uma Revenda</label>
                                    <cc-select-v2
                                        :tracker="'id'"
                                        :text="'name'"
                                        :searchable="true"
                                        :options="resales"
                                        v-model="filters.resale" />
                                </div>
                                <div class="col col-3 filter-controls">
                                    <button type="submit" style="display:none;" />
                                    <cc-button
                                        :nolink="true"
                                        type="button"
                                        :classes="'fixed danger-outline'"
                                        :icon="'far fa-trash-alt'"
                                        :content="'Limpar Filtros'"
                                        @click="filters = {};list()" />
                                    <cc-button
                                        :classes="'fixed'"
                                        :icon="'fas fa-chevron-left'"
                                        :content="'Voltar'"
                                        :link="'/clientes'" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading"  class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Cliente</th>
                                    <th>CNPJ</th>
                                    <th>Nome Fantasia</th>
                                    <th>Estado</th>
                                    <th>Cidade</th>
                                    <th>Segmento</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="client in clients">
                                    <tr v-bind:key="'head-office-' + client.head_office.id"
                                        v-if="client.head_office" @click="client.selected = !client.selected"
                                        class="client" :class="{ selected: client.selected }">
                                            <td class="pl-3">
                                                <cc-checkbox :checked="client.selected" @change="(evt) => {client.selected = evt.target.checked}"/>
                                            </td>
                                            <td>
                                                #{{ client.id }}
                                            </td>
                                            <td class="text-uppercase text-nowrap">
                                                <i class="fas fa-user-tie seller-icon"
                                                    :title="client.contact ? client.contact.toUpperCase() : ''"
                                                    :style="{ visibility: !client.head_office.is_head_office ? 'hidden' : 'visible' }"></i>
                                                {{ client.head_office.social_reazon }}
                                            </td>
                                            <td class="text-nowrap">{{ client.head_office.cnpj || "" | VMask('## ### ###/####-##') }}</td>
                                            <td>{{ client.head_office.trading_name }}</td>
                                            <td>{{ client.head_office.state && client.head_office.state.name }}</td>
                                            <td>{{ client.head_office.city && client.head_office.city.name }}</td>
                                            <td>{{ client.sectors && client.sectors.map(s => s.name).filter(s => s).join(', ') }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="paginaAtual"
                        :page_limit="11"
                        v-on:change="list($event)"></cc-paginacao>
                </div>
            </div>
            <ClientReport
                v-if="show_report && selected_clients.length > 1"
                :clients="selected_clients" />
        </div>
        <button class="analyze" title="Analisar" @click="open_report" v-if="!show_report">
            <i class="fas fa-chart-line"></i>
        </button>
        <button class="analyze return" title="Voltar" @click="close_report" v-else>
            <i class="fas fa-chevron-left"></i>
        </button>
    </div> -->
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/QuotationService";
import ClientService from "@/services/v3/client.service";
import ReportService from "@/services/v3/providers/report.service";
import ResaleService from "@/services/v3/resales/resale.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import NewClientModal from "@/modals/client/new/new-client.modal";
import ClientReport from "@/components/clients/analytics/_providers_x_clients.component"
import StoresModal from "@/modals/client/stores/management.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service"
import UserManagementModal from "@/modals/client/users/management.modal";
import Button from "@/components/ui/buttons/StandardButton.vue";
import StandardInput from "@/components/ui/inputs/StandardInput.vue";
import StandardInputListFilteredAuto from '@/components/ui/inputs/StandardInputListFilteredAuto.vue';

export default {
    mixins: [ loaderMixin ],
    components: {
        ccPaginacao: Paginacao,
        StoresModal,
        ClientReport,
        'user-management-modal': UserManagementModal,
        'cc-invitations-modal' : InvitationsModal,
        'cc-new-client-modal' : NewClientModal,
        Button,
        StandardInput,
        StandardInputListFilteredAuto,
    },
    data(){
        return{
          image:"/images/search.png",
            is_loading: false,
            paginaAtual: 1,
            total_items: 1,
            states: [],
            ids: [],
            cities: [],
            resales: [],
            show_report: false,
            filters: {
                city: {
                  cid_id: '',
                  cid_nome: '',
                },
                state: {
                  est_sigla: '',
                },
                resale: {
                  id: '',
                  name: '',
                }
            },
            items_by_page_value: 50,
            service: new QuotationService(),
            svc: new ClientService(),
            pr_svc: new ReportService(),
            user_svc: new UserService(),
            resale_svc: new ResaleService(),
            clients: [],
            selected_clients: [],
            statuses: [ { key: 'Todos', value: null }, { key: 'Inativos', value: 0 }, { key: 'Ativos', value: 1 } ],
            breadcrumbs: [
                {
                    name: "Clientes",
                    route: 'clients'
                },
                {
                    name: "Mapa de Fornecedores x Clientes",
                    route: 'analytic-clients'
                }
            ],
            statesList: [],
            citiesList: [],
            resalesList: [],
            selectCity: 'Selecione a cidade',
        }
    },
    methods: {
        setResaleValue(value) {
          this.filters.resale.id = value.id;
          this.filters.resale.name = value.name;
          this.list();
        },
        setCityValue(value) {
          this.filters.city.cid_id = value.cid_id;
          this.filters.city.cid_nome = value.cid_nome;
          this.list()
        },
        setStateValue(value) {
          this.filters.state.est_sigla = value.est_sigla;
          this.list();
        },
        clearFilters() {
          this.filters.state.est_sigla = ''; 
          this.filters.city.cid_id = '';
          this.filters.resale.id = '';
          this.list();
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        close_report() {
            this.show_report = false
            this.selected_clients = null
        },
        open_report() {
            this.selected_clients = this.clients.filter(c => c.selected == true)
            if(this.selected_clients.length > 1) {
                this.show_report = true
            }
        },
        load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.states = data.estados
        this.cities = data.cidades

        this.states.forEach(data => {
          this.statesList.push({
            text: data.est_nome,
            value: data,
          })
        })

        this.cities.forEach(data => {
          this.citiesList.push({
            text: data.cid_nome,
            value: data,
          })
        })
			})
		},
        load_resale_data() {
			return this.resale_svc.list().then(response => response.data).then(data => {
				this.resales = data.data;

        this.resales.forEach(data => {
          this.resalesList.push({
            text: data.name,
            value: data,
          })
        })
			})
		},
        list(new_page = this.paginaAtual) {
            this.paginaAtual = new_page;
            this.is_loading = true;
            this.svc.analytic({
                page: new_page,
                state: this.filters.state?.est_sigla,
                city: this.filters.city?.cid_id,
                resale: this.filters.resale?.id,
                sort: 'l.loj_razao'
            }).then(response => response.data).then(data => {
                data.data.forEach(c => c.selected = false)
                this.clients = data.data;
                this.total_items = data.meta.total;
                this.is_loading = false;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    computed: {
		available_cities : function() {
			return this.filters.state
                ? this.cities.filter(c => c.est_id == this.filters.state.est_id)
                : []
		}
	},
    watch: {
        /* filters : {
            handler: function() {
                this.list(1)
            },
            deep: true
        } */
    },
    created() {
        this.load_region_data()
        this.load_resale_data()
        this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .seller-icon {
        color: $blue1;
        font-size: 1.4rem;
        position: relative;
        top: 5px;
    }

    .filter-controls {
        button {
            margin-right: 10px;
        }
    }

    button {
        &.analyze {
            background: #5d61d3;
            position: absolute;
            bottom: 3rem;
            right: 3rem;
            border-radius: 40px;
            border: none;
            padding: 20px;
            width: 70px;
            height: 70px;
            color: white;
            &:focus {
                outline: none;
            }
            &:hover {
                filter: drop-shadow(2px 4px 6px gray);
            }
            i {
                color: #80ffcc;
                font-size: 2rem;
            }
            &.return {
                background: $primary-color;
                &:hover {
                    filter: drop-shadow(2px 4px 6px $primary-color);
                }
                i {
                    color: white;
                    font-size: 2rem;
                    filter: hue-rotate(45deg);
                }
            }
        }

    }


    .rate {
        background-color: white;
        padding: 0.5rem 0.5rem;
        border-radius: 5px;
        color: black;
        cursor: pointer;
        i {
            font-size: 1.3rem;
            color: $yellow1;
        }
        &:hover {
            color: white;
            background-color: $blue1;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        i {
            margin-left: 10px;
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }

.monitors {
  font-size: 1vw;
  margin-right: 4em;
}

/* NEW CSS */
.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.orders-page__top-actions-container {
  display: flex;
  flex-direction: row-reverse;
  padding: 2em 0em;
}
.orders-page__actions-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0em;
}
.orders-page__content-inputs {
  width: 30vw;
}
.page-table-container {
  margin: 2em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.select-width {
  width: 3.5%;
}
.id-width {
  width: 4.5%;
}
.client-width {
  width: 33.5%;
}
.cnpj-width {
  width: 12.5%;
}
.trade-name-width {
  width: 12.5%;
}
.state-width {
  width: 12.5%;
}
.city-width {
  width: 12.5%;
}
.segment-width {
  width: 8.5%;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.page-table-header-mobile {
  display: none;
}
.checkbox-wrapper {
  padding-left: 1em;
  padding-top: 0.5em;
}
.page-selected-filters-container {
  display: flex;
  margin-bottom: 2em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}


@media only screen and (max-width: 1000px){
  .page-table-header{
    display: none;
  }
  .page-container {
    font-size: 1.5vw;
    margin-right: 5vw;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.2em;
  }
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
  .checkbox-width {
    width: 100%;
  }
  .trade-name-width {
    width: 100%;
  }
  .state-width {
    width: 100%;
  }
  .city-width {
    width: 100%;
  }
  .segment-width {
    width: 100%;
  }
  .ean-code-width {
    width: 100%;
  }
  .gtin-code-width {
    width: 100%;
  }
  .client-width {
    width: 100%;
  }
  .cnpj-width {
    width: 100%;
  }
  .client-width {
    width: 100%;
  }
  .last-date-width {
    width: 100%;
  }
  .edit-width {
    width: 100%;
  }
  .page-table-header-mobile{
    display: block;
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    text-align: left;
  }
}
@media only screen and (max-width: 800px) {
  .page-container {
    font-size: 2.5vw;
  }
  .products-page__inputs-container{
    flex-direction: column;
    gap: 2vh;
  }
  .products-page__inputs-input{
    width: 100%;
  }
  .products-page__actions-container{
    flex-direction: column;
    gap: 2vh;
  }
  .products-page__right-content{
    flex-direction: column;
    width: 100%;
    gap: 2vh;
  }
  .products-page__left-content{
    flex-direction: column;
    gap: 2vh;
  }
  .action-buttons{
    margin-left: 0;
    width: 100%;
  }
  .page-filters__right-select-wrapper{width: 100vw;}
  .lastbuydate_wrapper{padding-top: 0;}
}
@media only screen and (max-width: 550px) {
  .page-container {
    font-size: 3.5vw;
  }
}
</style>
