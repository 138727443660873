<template>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="header-actions">
            <div class="row">
                <div class="col actions">
                    <cc-button :content="'Voltar'"
                        :icon="'fas fa-chevron-left'"
                        @click="current_seller = null;VIEW = 'SELLER_LIST'"
                        :classes="'fill default fixed'"
                        v-if="VIEW != 'SELLER_LIST'" />
                </div>
            </div>
        </div>
        <div slot="body" class="container-fluid mb-2" id="sellers-content">
            <div class="row">
                <SellerParticipation
                    v-if="VIEW == 'SELLER_PARTICIPATION'"
                    :request_seller="{ seller: current_seller }"
                />
                <div class="col-12 pb-4 p-0 d-flex align-items-center" v-if="VIEW == 'SELLER_LIST'">
                    <div class="col col-4">
                        <cc-search v-model="filters.name" :placeholder="'Filtrar por nome'"/>
                    </div>
                </div>
                <div class="col" v-show="VIEW == 'SELLER_LIST'">
                    <cc-loader v-show="loading"/>
                    <no-data-available v-if="!loading && sellers && sellers.length == 0" :text="'Sem usuários cadastrados'" />
                    <table class="table" v-show="!loading && sellers && sellers.length > 0">
                        <thead>
                            <tr>
                                <th class="pl-2">Nome</th>
                                <th>Login</th>
                                <th>Telefone</th>
                                <th>Senha</th>
                                <th>Criado em</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="seller in sellers" v-bind:key="seller.id">
                                <td>{{ seller.name }}</td>
                                <td>{{ seller.login }}</td>
                                <td><cc-whatsapp :phone="seller.phone" /></td>
                                <td>{{ seller.password }}</td>
                                <td><cc-percentage :value="seller.participation" /></td>
                                <td>{{ seller.created_at | formatDate }}</td>
                                <td>
                                    <div class="actions">
                                        <i @click="show_participations(seller)" title="Histórico de Cotações" class="fas fa-clipboard-list"></i>
                                        <toggle-button
                                            :value="seller.is_attending"
                                            @change="toggle_seller(seller)"
                                            :sync="true"
                                            :color="'#ff7110'"
                                            :height="20"/>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="total_items > 15">
                                <td colspan="7" style="height:6rem">
                                    <Pagination
                                        classes="orange justify-end"
                                        :total="total_items"
                                        :items_by_page_value="15"
                                        :page="page"
                                        :page_limit="9"
                                        v-on:change="($event) => load($event)"
                                    ></Pagination>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </cc-modal> -->
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
                <span class="new-product__header-title">
                    Vendedores
                </span>
                <span @click="close()" class="material-icons new-product__closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="buttons-container">
                <Button v-if="VIEW != 'SELLER_LIST'" :iconName="'arrow_back'" title="Voltar" :text="'Voltar'"
                    :action="() => { current_seller = null; VIEW = 'SELLER_LIST' }" />
            </div>
            <SellerParticipation v-if="VIEW == 'SELLER_PARTICIPATION'" :request_seller="{ seller: current_seller }" />
            <div class="search-filter-container" v-if="VIEW == 'SELLER_LIST'">
                <cc-search v-model="filters.name" :placeholder="'Filtrar por nome'" />
            </div>
            <cc-loader style="margin-top: 20px;" v-if="loading" />
            <no-data-available v-if="!loading && sellers.length == 0" :text="'Sem usuários cadastrados'" />
            <div v-if="!loading && VIEW == 'SELLER_LIST' && sellers.length > 0" class="page-table-container">
                <div class="page-table-content">
                    <div class="page-table-header">
                        <div class="page-table-header-text name-width">Nome</div>
                        <div class="page-table-header-text login-width">Login</div>
                        <div class="page-table-header-text phone-width">Telefone</div>
                        <div class="page-table-desc-column percentage-width">Porcentagem</div>
                        <div class="page-table-header-text password-width">Senha</div>
                        <div class="page-table-header-text created-at-width">Criado em</div>
                        <div class="page-table-header-text actions-width">Ações</div>
                    </div>
                    <div v-for="(seller, idx) in sellers" :key="seller.id" :class="getSpecialBackground(idx)">
                        <div class="page-table-row">
                            <div class="page-table-desc-column name-width">{{ seller.name }}</div>
                            <div class="page-table-desc-column login-width">{{ seller.login }}</div>
                            <div class="page-table-desc-column phone-width">
                                <cc-whatsapp :phone="seller.phone" />
                            </div>
                            <div class="page-table-desc-column percentage-width">
                                <cc-percentage :value="seller.participation" />
                            </div>
                            <div class="page-table-desc-column password-width">{{ seller.password }}</div>
                            <div class="page-table-desc-column created-at-width">{{ seller.created_at | formatDate }}</div>
                            <div class="page-table-desc-column actions-width">
                                <div class="actions">
                                    <span title="Histórico de Cotações" @click="show_participations(seller)"
                                        class="material-icons-outlined assignment-icon">
                                        assignment
                                    </span>
                                    <toggle-button :value="seller.is_attending" @change="toggle_seller(seller)" :sync="true"
                                        :color="'#ff7110'" :height="20" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <tr v-show="total_items > 15">
                    <td colspan="7" style="height:6rem">
                        <Pagination classes="orange justify-end" 
                                    :total="total_items" 
                                    :items_by_page_value="15" 
                                    :page="page"
                                    :page_limit="9" v-on:change="($event) => load($event)">
                        </Pagination>
                    </td>
                </tr>
            </div>
        </div>
    </div>
</template>

<script>
import ProviderService from '@/services/v3/provider.service'
import SellerService from '@/services/v3/providers/seller.service'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Pagination from "@/components/cliente/base-components/Pagination";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import SellerParticipation from '@/modals/request-providers/components/seller-participation.partial.vue'
import Button from '@/components/ui/buttons/StandardButton.vue';

export default {
    mixins: [loaderMixin],
    props: ["provider", "client"],
    components: {
        SellerParticipation,
        Pagination,
        Button,
    },
    data() {
        return {
            svc: new ProviderService(),
            seller_svc: new SellerService(),
            sellers: null,
            loading: true,
            filters: {},
            profiles: [],
            current_seller: null,
            is_edit_seller_open: false,
            VIEW: "SELLER_LIST",
            is_list_seller_clients_open: false,
            total_items: 0,
            page: 1,
            sellers: [],
            modal: {
                title: `Vendedores #${this.provider.name}`,
                subtitle: `CLIENTE #${this.client.name}`,
                icon_title: 'fas fa-user-friends',
                style: {
                    width: "80%"
                }
            }
        }
    },
    watch: {
        current_seller: function () {
            if (this.current_seller == null) {
                this.update_modal({ title: `Vendedores #${this.provider.name}`, subtitle: `CLIENTE #${this.client.name}` })
            }
        },
        filters: {
            handler: function () {
                this.page = 1
                this.load()
            },
            deep: true
        }
    },
    methods: {
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        show_participations(seller) {
            this.VIEW = 'SELLER_PARTICIPATION'
            this.current_seller = seller
            this.update_modal({ title: 'Histórico de Cotações', subtitle: `#${seller.name}` })
        },
        toggle_seller(seller) {
            this.seller_svc.toggle_client({ ...this.client, is_attending: seller.is_attending }, seller.id).then(resp => {
                seller.is_attending = !seller.is_attending
            })
        },
        remove_seller(seller) {
            this.confirm_action({
                message: `Confirma remoção do vendedor ${seller.name}?`,
                callback: () => this.remove(seller.id)
            })
        },
        update_modal(new_settings) {
            this.modal = { ...this.modal, ...new_settings }
        },
        toggle_status(seller) {
            this.seller_svc.update_status(seller.id, { status: !seller.status }).then(resp => {
                seller.status = !seller.status
            })
        },
        close() {
            this.$emit('close')
        },
        load(page = this.page) {
            this.loading = true
            this.page = page
            return this.svc.load_sellers(this.provider.id, { page: this.page, name: this.filters.name })
                .then(response => response.data).then((data) => {
                    data.data.forEach(s => s.is_attending = s.clients.some(c => c.id == this.client.id))
                    this.sellers = data.data
                    this.$forceUpdate()
                    this.loading = false
                    this.total_items = data.meta.total
                }).catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                })
        }
    },
    async created() {
        await this.load()
    }
}
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    width: 80vw;
    height: 80vh;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.new-product__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.6em;
}

.new-product__closebtn-icon {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.page-table-container {
    margin: 2em;
    max-height: 50%;
    overflow: auto;
}

.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    padding: 1em 0em;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
    align-items: center;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
    text-align: left;
}

.page-table-line-special {
    background: #F7F7F7;
}

.name-width {
    width: 14%;
}

.login-width {
    width: 14%;
}

.phone-width {
    width: 14%;
}

.percentage-width {
    width: 14%;
}

.password-width {
    width: 14%;
}

.created-at-width {
    width: 14%;
}

.actions-width {
    width: 14%;
}

.buttons-container {
    display: flex;
    flex-direction: row-reverse;
    padding: 1em 2em;
}

.search-filter-container {
    width: 35vw;
    padding: 0em 2em;
}

.assignment-icon {
    color: #20CE88;
    cursor: pointer;
    margin-right: 10px;
}


@import '@/sass/commons/_variables';

#sellers-content {
    height: 50vh;
}

// /deep/.modal-body {
//     padding-bottom: 10rem;
// }
.change-status {
    display: flex;
    align-items: center;
    min-width: 15rem;

    .success-update {
        margin-left: 5px;
        visibility: hidden;
        color: $brand-success;

        &.visibile {
            visibility: 'visible';
        }
    }
}

.actions {
    display: flex;
    border: none;

    a {
        margin-right: 5px !important;
    }
}

.canceled {
    i {
        color: $brand-danger;
    }
}

.waiting {
    i {
        color: $yellow2;
    }
}

.confirmed {
    i {
        color: $ternary-color;
    }
}

.error {
    position: absolute;
    color: $lower-red;
}

i.remove-btn {
    color: $lower-red !important;
    font-size: 15px;
    cursor: pointer;
}

.filters {
    padding: 10px 15px;
}

/deep/ .cc-loader img {
    height: 5rem;
}

/deep/ .vue-js-switch {
    margin: 0;
}
</style>
