import http from "../http";
import Service from "../../Service";

/**
 * @type ReportService
 */
export default class ReportService extends Service {

    clients_report(params) {
        return http.get(`admin/providers/reports/clients`, {
			headers: this.getHeaders(),
            params
		});
    }
}
